import React, { useState } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import {
  Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import useApplication from '@frontend/context/Application';
import { buildTranslatedUrl } from '@frontend/services/utilities';
import Button from '../components/basic/Button';
import { ReactComponent as Folder } from '../images/icons/folder.svg';
import geberitLogo from '../images/geberit-holtzmann.png';
import useUser from '../context/User';
import useTranslations from '../context/Translations';

const Header = () => {
  const { locale, isLocaleInURL } = useApplication();
  const { currentUser, onLogout } = useUser();
  const { getTranslation } = useTranslations();
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const mobileClass = isMobileOnly ? 'mobile' : '';

  return (
    <header className={`header-outer-container ${mobileClass}`}>
      <div className="container ">
        <Row>
          <Col>
            <Link to={buildTranslatedUrl('/', locale, isLocaleInURL)} className={`c-logo header-geberit-home-link ${mobileClass}`}>
              <img src={geberitLogo} alt="GEBERIT für Holtzmann logo" className={`header-logo ${mobileClass}`} />
            </Link>

            { currentUser
              && (
              <Dropdown isOpen={dropdownOpen} className="header-dropdown-container" toggle={toggle}>
                <DropdownToggle tag="span" className="header-dropdown-toggle">
                  {!isMobileOnly
                    && (
                    <Button color={(isTablet) ? 'secondary' : 'flat'} onClick={toggle}>
                      <span className="icon web20-icon web20-icon-account mr-2" />
                      {currentUser?.email}
                      <i className="web20-icon web20-icon-chevron-small-down ml-2 mr-0" />
                    </Button>
                    )}
                  {isMobileOnly
                    && (
                    <button
                      className={`hamburger hamburger--squeeze ${dropdownOpen ? 'is-active' : ''}`}
                      type="button"
                      onClick={toggle}
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                    )}
                </DropdownToggle>
                <DropdownMenu className={`header-dropdown-menu ${mobileClass}`} right>
                  {isMobileOnly && <div className="header-current-user-item">{currentUser?.email}</div>}
                  <DropdownItem onClick={() => history.push(buildTranslatedUrl('/projects', locale, isLocaleInURL))} className="header-dropdown-menu-item">
                    <Folder width="16" height="16" className="header-icon web20-icon ml-0 mr-2" />
                    { getTranslation('ActionMenuCiamProjects') }
                  </DropdownItem>
                  <DropdownItem onClick={() => history.push(buildTranslatedUrl('/profile', locale, isLocaleInURL))} className="header-dropdown-menu-item">
                    <i className="web20-icon web20-icon-account ml-0 mr-2" />
                    { getTranslation('ActionMenuCiamGeberitId') }
                  </DropdownItem>
                  <DropdownItem onClick={onLogout} className="header-dropdown-menu-item">
                    <i className="web20-icon web20-icon-account ml-0 mr-2" />
                    { getTranslation('ActionMenuCiamLogout') }
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              )}
          </Col>

        </Row>
      </div>
    </header>
  );
};

export default Header;
