import { ApiRequestConfig } from '../types/api-requests/ApiRequestConfig';
import { ApiRequestConfigInfo } from '../types/api-requests/ApiRequestConfigInfo';
import { NameValueMap } from '../types/KeyValueMap';

export default class ApiRequestBuilder {
    private readonly apiRequestConfig: ApiRequestConfig = {
      info: undefined,
      body: undefined,
      fileFields: [],
      options: {
        handleLoading: false,
        handleErrorNotification: false,
      },
    };

    constructor(initConfig?: ApiRequestConfig) {
      if (initConfig) {
        this.apiRequestConfig = JSON.parse(JSON.stringify(initConfig));
      }
    }

    public setEndpoint(endpointInfo: ApiRequestConfigInfo, replaceUrl?: NameValueMap<string|number>): ApiRequestBuilder {
      this.apiRequestConfig.info = JSON.parse(JSON.stringify(endpointInfo));
      if (replaceUrl && this.apiRequestConfig.info) {
        this.apiRequestConfig.info.replaceUrl = replaceUrl;
      }
      return this;
    }

    public setToken(accessToken?: string): ApiRequestBuilder {
      this.apiRequestConfig.accessToken = accessToken;
      return this;
    }

    public setBody(bodyInfo: NameValueMap<any>): ApiRequestBuilder {
      this.apiRequestConfig.body = bodyInfo; // JSON.parse(JSON.stringify(bodyInfo));
      return this;
    }

    public setFileFields(fields: string[]): ApiRequestBuilder {
      this.apiRequestConfig.fileFields = fields;
      return this;
    }

    public addToBody(bodyInfo: NameValueMap<any>): ApiRequestBuilder {
      const self = this;
      if (bodyInfo) {
        if (!self.apiRequestConfig.body) {
          self.apiRequestConfig.body = {};
        }
        Object.keys(bodyInfo).forEach((thisIdKey) => {
          if (self.apiRequestConfig.body) {
            self.apiRequestConfig.body[thisIdKey] = bodyInfo[thisIdKey];
          }
        });
      }
      return this;
    }

    public setHandleLoading(handleLoading: boolean): ApiRequestBuilder {
      if (this.apiRequestConfig.options) {
        this.apiRequestConfig.options.handleLoading = handleLoading;
      }
      return this;
    }

    public setHandleErrorNotification(handleErrorNotification: boolean): ApiRequestBuilder {
      this.apiRequestConfig.options.handleErrorNotification = handleErrorNotification;
      return this;
    }

    public build(): ApiRequestConfig {
      return this.apiRequestConfig;
    }
}
