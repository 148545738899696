import React, {
  createContext, useContext, // useState,
} from 'react';
// eslint-disable-next-line import/no-unresolved
import translationService from '@core/src/services/translation.service';
import useApplication from './Application';

export interface ProviderService {
  getTranslation: (key: string) => string,
  locale: string,
}

export interface TranslationProviderProps {
  children?: React.ReactNode;
}

const TranslationContext = createContext<ProviderService>({} as ProviderService);

const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const { locale } = useApplication();

  // set HTML language tag for current locale/language
  document.documentElement.lang = locale.split('-')[0] || '';

  const getTranslation = (key: string): string => translationService.getTranslation(key, locale);

  return (
    <TranslationContext.Provider value={{ getTranslation, locale }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslations = () => useContext(TranslationContext);

export default useTranslations;
export { TranslationProvider, TranslationContext };
