import _ from 'lodash';
import { ProjectDatalayer, ProjectDatalayerProps } from '../context/Project';
import { BACKEND_API_ENDPOINTS } from '../requests/api-endpoints-list';
import ApiRequestBuilder from '../requests/api-request.builder';
import { ProjectModel } from '../types/classes/Project';
import { ReportCalculationsInfoResponse } from '../types/classes/ReportCalculationsInfoResponse';

const ProjectLocalService = ({ callApi }: ProjectDatalayerProps): ProjectDatalayer => {
  const getProjects = async (): Promise<ProjectModel[]> => {
    const localProjects = localStorage.getItem('gdrh:projects');
    if (localProjects) {
      return JSON.parse(localProjects);
    }
    return [];
  };

  const setProjects = (projects: ProjectModel[]) => {
    localStorage.setItem('gdrh:projects', JSON.stringify(projects));
  };

  const getLocalClientId = async (): Promise<string> => {
    const localId = localStorage.getItem('gdrh:clientId');
    if (localId) {
      return localId;
    }
    const apiConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.getNewClientId)
      .build();
    const response = await callApi(apiConfig)
      .catch((err) => console.log('Error Creating new ClientId', err)); // TODO: proper error handling
    if (response) {
      localStorage.setItem('gdrh:clientId', response);
      return response;
    }
    throw new Error('CreateClientId failed');
  };

  const getProject = async (ProjectUid: string): Promise<ProjectModel | null> => {
    const allProjects = await getProjects();
    if (allProjects) {
      return allProjects.find((project) => project.uid === ProjectUid) || null;
    }
    return null;
  };

  const addProject = async (project: ProjectModel, projectImage: File | null = null): Promise<ProjectModel> => {
    if (projectImage) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.uploadAnonymousProjectImage, { projectId: project.uid })
        .setBody({ projectImage })
        .setFileFields(['projectImage'])
        .build();
      project.details.imageUrl = await callApi(apiConfig).then((response) => response.data);
    }

    const allProjects = await getProjects();
    allProjects.push(project);
    setProjects(allProjects);
    return project;
  };

  const removeProject = async (project: ProjectModel): Promise<void> => {
    if (project.details.imageUrl) {
      delete project.details.imageUrl;
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.deleteAnonymousProject, { projectId: project.uid })
        .build();
      await callApi(apiConfig)
        .catch((err) => {
          throw err;
        });
    }

    const allProjects = await getProjects();
    allProjects.unshift();
    setProjects(allProjects.filter((thisProject) => thisProject.uid !== project.uid));
  };

  const updateProject = async (
    project: ProjectModel,
    projectImage: File | null,
  ): Promise<ProjectModel> => {
    const allProjects = await getProjects();

    /* make a copy of the project without report data
    so we do not overcrowd the browser local storage */
    const projectCopy = _.cloneDeep(project);
    delete projectCopy.lastReport;

    const apiConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.updateAnonymousProjectImage, { projectId: projectCopy.uid })
      .setBody({ project: projectCopy, projectImage })
      .setFileFields(['projectImage'])
      .build();
    const newImageUrl = await callApi(apiConfig).then((res) => res.details.imageUrl)
      .catch((err) => {
        throw err;
      });

    projectCopy.details.imageUrl = newImageUrl;
    allProjects.splice(allProjects.findIndex((p) => p.uid === projectCopy.uid),
      1,
      projectCopy);
    setProjects(allProjects);
    return project;
  };

  const generateReport = async (project: ProjectModel): Promise<ReportCalculationsInfoResponse> => {
    const clientId = await getLocalClientId();
    const apiConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.projectReportGuest)
      .addToBody({ project, clientId })
      .build();
    return callApi(apiConfig)
      .then((response) => response)
      .catch((err) => Promise.reject(err));
  };

  return {
    getProject,
    getProjects,
    addProject,
    removeProject,
    updateProject,
    generateReport,
  };
};

export default ProjectLocalService;
