import React, { useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import TagManager from 'react-gtm-module';

import {
  BrowserRouter, Route, Switch, Redirect, useLocation,
} from 'react-router-dom';

// style imports
import './styles/bootstrap_loader.scss';
import './styles/styles.geberit.web20.min.css';
import './styles/styles_common.css';
import './styles/global.scss';

// import Navigation from './layout/Navigation';
import { getLocaleConfigByDomain, urlLocaleRegex } from '@core/src/services/language.service';
import { NotificationProvider } from './context/NotificationProvider';
import Footer from './layout/Footer';
import Header from './layout/Header';
import { ProjectProvider } from './context/Project';
import { TranslationProvider } from './context/Translations';
import useProductDbService from './services/useProductDbService';
import { castLocale } from './types/LocalMarketMap';
import authenticationService from './services/authentication.service';
import { UserProvider } from './context/User';
import { ProductProvider } from './context/ProductProvider';
import { LoadingOverlayProvider } from './context/LoadingOverlay';
import LoadingOverlay from './components/layout/LoadingOverlay';
import useDatalayer from './services/useDatalayer';
import { ApplicationProvider } from './context/Application';

// config Google Tag Manager
if (process.env.REACT_APP_GTMID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTMID });
}

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const BaseLayout = React.lazy(() => import('./layout/BaseLayout'));
const Page404 = React.lazy(() => import('./views/Error404'));
const Page500 = React.lazy(() => import('./views/Error500'));
const Home = React.lazy(() => import('./views/Home'));
const Profile = React.lazy(() => import('./views/Profile'));

interface BaseComponentWrapperProps {
  children?: React.ReactNode
}

const BaseComponentWrapper = ({ children }: BaseComponentWrapperProps) => {
  const { pathname } = useLocation();
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <ApplicationProvider>
      <TranslationProvider>
        <NotificationProvider>
          <UserProvider>
            <ProductProvider datalayer={useProductDbService()}>
              <LoadingOverlayProvider>
                <ProjectProvider datalayer={useDatalayer()}>
                  <Header />
                  <LoadingOverlay />
                  <div className={`main-container ${isMobileOnly ? 'mobile' : ''}`}>
                    <div id="top" ref={containerRef} />
                    <div className="content-container">
                      {children}
                    </div>
                    <Footer />
                  </div>
                </ProjectProvider>
              </LoadingOverlayProvider>
            </ProductProvider>
          </UserProvider>
        </NotificationProvider>
      </TranslationProvider>
    </ApplicationProvider>
  );
};

const UnauthenticatedRoute = ({ component: Component, ...rest }: any) => (
  <Route
    /* eslint-disable react/jsx-props-no-spreading */
    {...rest}
    render={(props) => {
      const { locale } = props.match.params;
      const domainLocale = getLocaleConfigByDomain(window.location.hostname);

      if (locale && locale !== castLocale(locale)) {
        return <Redirect to={props.location.pathname.replace(locale, castLocale(locale))} />;
      }

      if (!locale && domainLocale && domainLocale !== castLocale(domainLocale)) {
        return <Redirect to={props.location.pathname.replace(domainLocale, castLocale(domainLocale))} />;
      }

      return (
        !authenticationService.hasTokenLogin()
          ? (
            <BaseComponentWrapper>
              <Component {...props} />
            </BaseComponentWrapper>
          )
          : <Redirect to={`${locale ? `/${locale}/projects` : '/projects'}`} />
      );
    }}
  />
);

const AuthenticatedRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      const { locale } = props.match.params;
      const domainLocale = getLocaleConfigByDomain(window.location.hostname);

      if (locale && locale !== castLocale(locale)) {
        return <Redirect to={props.location.pathname.replace(locale, castLocale(locale))} />;
      }

      if (!locale && domainLocale && domainLocale !== castLocale(domainLocale)) {
        return <Redirect to={props.location.pathname.replace(domainLocale, castLocale(domainLocale))} />;
      }

      return (
        authenticationService.hasTokenLogin()
          ? (
            <BaseComponentWrapper>
              <Component {...props} />
            </BaseComponentWrapper>
          )
          : <Redirect to={`${locale ? `/${locale}/` : '/'}`} />
      );
    }}
  />
);

const HybridRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      const { locale } = props.match.params;
      const domainLocale = getLocaleConfigByDomain(window.location.hostname);

      if (locale && locale !== castLocale(locale)) {
        return <Redirect to={props.location.pathname.replace(locale, castLocale(locale))} />;
      }

      if (!locale && domainLocale && domainLocale !== castLocale(domainLocale)) {
        return <Redirect to={props.location.pathname.replace(domainLocale, castLocale(domainLocale))} />;
      }
      return (
        <BaseComponentWrapper>
          <Component {...props} />
        </BaseComponentWrapper>
      );
    }}
  />
);

export const NotFoundRoute = ({ component: Component, ...rest }: any) => (
  <Route
    /* eslint-disable react/jsx-props-no-spreading */
    {...rest}
    render={(props) => {
      const { locale } = props.match.params;

      if (locale) {
        return <Redirect to={`/${castLocale(locale)}/404`} />;
      }

      return <Redirect to="/404" />;
    }}
  />
);

const App = () => (
  <BrowserRouter>
    <React.Suspense fallback={loading()}>
      <Switch>
        <HybridRoute exact path={`/:locale${urlLocaleRegex}?/404`} name="Page 404" component={Page404} />
        <HybridRoute exact path={`/:locale${urlLocaleRegex}?/500`} name="Page 500" component={Page500} />
        <UnauthenticatedRoute path={`/:locale${urlLocaleRegex}?/`} exact name="Home" component={Home} />
        <AuthenticatedRoute path={`/:locale${urlLocaleRegex}?/profile`} exact name="User Profile" component={Profile} />
        <HybridRoute path={`/:locale${urlLocaleRegex}?/`} name="Content" component={BaseLayout} />
        <NotFoundRoute from="*" to={`/:locale${urlLocaleRegex}/404`} />
      </Switch>
    </React.Suspense>
  </BrowserRouter>
);
export default App;
