import { allProductImages } from '@frontend/images/DynamicImages';
import { ProductDatalayer } from '../context/ProductProvider';
import { BACKEND_API_ENDPOINTS } from '../requests/api-endpoints-list';
import ApiRequestBuilder from '../requests/api-request.builder';
import { ProductDTO, VisualElementType } from '../types/classes/Product';
import { SelectionObject } from '../types/classes/SelectionObject';
import useApiRequestService from './useApiRequestService';

const useProductDbService = (): ProductDatalayer => {
  const { callApi } = useApiRequestService();

  const getPictureUrl = (picture: string | undefined) => {
    if (!picture) {
      return '';
    }
    return allProductImages[picture];
  };

  const getProducts = (marketId?: string): Promise<SelectionObject[]> => {
    const requestConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.products, { marketId: marketId || 'Germany' })
      .build();

    return new Promise((resolve, reject) => {
      callApi(requestConfig).then((response) => {
        const products: ProductDTO[] = response;

        const selectionObjects = products.map((product) => {
          const selectionObject = {} as SelectionObject;

          selectionObject.objectType = product.ident;
          selectionObject.title = product.title;
          selectionObject.description = product.title;
          selectionObject.category = product.category;
          selectionObject.subCategory = product.subcategory;
          selectionObject.image = getPictureUrl(product.visualElements?.main?.picture);
          selectionObject.dimensions = {
            width: product.dimensions.width,
            minWidth: 10,
            maxWidth: 50,
            height: product.dimensions.height,
            minHeight: product.dimensions.minHeight,
            margin: product.dimensions.margin,
            defaultSpacing: product.dimensions.defaultSpacing,
          };
          selectionObject.subObjects = (Object.keys(product.visualElements) as Array<VisualElementType>)
            .filter((key) => key !== 'main')
            .map((key) => ({
              image: getPictureUrl(product.visualElements[key].picture),
              offsetY: product.visualElements[key].offsetY,
              cssOverride: product.visualElements[key].cssOverride || undefined,
            }));
          selectionObject.constructionElements = product.constructionElements.map(
            (constructionElement) => ({
              materialGroupId: constructionElement.MaterialGroupId,
              configItemId: constructionElement.ConfigItemId,
              configItemText: constructionElement.ConfigItemText,
              configurationGroupId: constructionElement.ConfigurationGroupId,
              configurationGroupIsDefault: constructionElement.ConfigurationGroupIsDefault,
              materialGroupIsDefault: constructionElement.MaterialGroupIsDefault,
              configurationGroupText: constructionElement.ConfigurationGroupText,
              installationSystem: constructionElement.InstallationSystem,
              materialGroupText: constructionElement.MaterialGroupText,
              defaultInstallationHeight: parseFloat((constructionElement.DefaultInstallationHeight * 100).toFixed(2)),
            }),
          );

          return selectionObject;
        });

        resolve(selectionObjects);
      }).catch((error) => reject(error));
    });
  };

  return {
    getProducts,
  };
};

export default useProductDbService;
