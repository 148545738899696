import LocaleConfig from '../conf/LocaleConfig.json';
// import Redirects from '../conf/Redirects.json';
// import { NameValueMap } from '@core/src/types/KeyValueMap';

export interface LocaleDetailConfigType {
  domains: string[];
  alternativeLanguages: string[];
}

// export const getDomainRedirects = (domain: string) : string | null => {
//   const redirects: NameValueMap<string> = Redirects;
//   return (domain in redirects) ? redirects[domain] : null;
// };

export const isValidLocale = (locale?: string): boolean => {
  if (locale !== undefined && locale !== null) {
    return Object.keys(LocaleConfig).includes(locale);
  }
  return false;
};

export const castLocale = (locale?: string): string => {
  if (locale === undefined || locale === null || !isValidLocale(locale)) return 'de-CH';

  const split = locale.split('-');
  return `${split[0].toLowerCase()}-${split[1].toUpperCase()}`;
};

export const urlLocaleRegex = '([a-z]{2}-[A-Z]{2})';

export const getLocaleConfigByDomain = (domain: string): string | undefined => {
  for (const [key, value] of Object.entries(LocaleConfig)) {
    if (value.domains.includes(domain)) {
      return key;
    }
  }
  return undefined;
};

export const getLocaleConfig = (locale: string): LocaleDetailConfigType | undefined => {
  for (const [key, value] of Object.entries(LocaleConfig)) {
    if (key === locale) {
      return value;
    }
  }
  return undefined;
};
