import React, { useContext, useState } from 'react';

export interface LoadingOverlayService {
  message: string;
  setOverlayMessage: (message: string) => void;
}

export interface LoadingOverlayProviderProps {
  children?: React.ReactNode;
}

const LoadingOverlayContext = React.createContext<LoadingOverlayService>({} as LoadingOverlayService);

const LoadingOverlayProvider = ({ children }: LoadingOverlayProviderProps) => {
  const [message, setMessage] = useState('');

  return (
    <LoadingOverlayContext.Provider
      value={{
        message,
        setOverlayMessage: setMessage,
      }}
    >
      {children}
    </LoadingOverlayContext.Provider>
  );
};

const useOverlay = () => useContext(LoadingOverlayContext);

export default useOverlay;
export { LoadingOverlayProvider };
