import { debounce } from 'throttle-debounce';
import { useCallback } from 'react';
import { ProjectDatalayer, ProjectDatalayerProps } from '../context/Project';
import { ProjectModel } from '../types/classes/Project';
import { ReportCalculationsInfoResponse } from '../types/classes/ReportCalculationsInfoResponse';
import ApiRequestBuilder from '../requests/api-request.builder';
import { BACKEND_API_ENDPOINTS } from '../requests/api-endpoints-list';
import { UserType } from '../types/classes/UserInfo';

const ProjectDbService = ({ callApi }: ProjectDatalayerProps): ProjectDatalayer => {
  const getProjects = async (currentUser: UserType|null): Promise<ProjectModel[]> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getUserProjects)
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .catch(() => []);
    }
    return [];
  };

  const getProject = async (ProjectUid: string, currentUser: UserType|null): Promise<ProjectModel | null> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getProject, { projectId: ProjectUid })
        .setToken(currentUser.accessToken)
        .build();
      return callApi(apiConfig)
        .catch((err) => {
          throw err;
        });
    }
    return null;
  };

  const addProject = async (
    project: ProjectModel,
    projectImage: File|null,
    currentUser: UserType | null,
  ): Promise<ProjectModel> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.projectCreate)
        .setBody({ project, projectImage })
        .setFileFields(['projectImage'])
        .setToken(currentUser.accessToken)
        .build();
      await callApi(apiConfig)
        .catch((err) => {
          throw err;
        });
    }
    return project;
  };

  const removeProject = async (project: ProjectModel, currentUser: UserType|null): Promise<void> => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.projectDelete, { projectId: project.uid })
        .setToken(currentUser.accessToken)
        .build();
      await callApi(apiConfig)
        .catch((err) => {
          throw err;
        });
    }
  };

  const updateProjectOnDatabase = async (
    project: ProjectModel,
    projectImage: File | null,
    currentUser: UserType | null,
  ) => {
    if (currentUser) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.projectUpdate, { projectId: project.uid })
        .setBody({ project, projectImage})
        .setFileFields(['projectImage'])
        .setToken(currentUser.accessToken)
        .build();
      await callApi(apiConfig)
        .catch((err) => {
          throw err;
        });
    }
    return null;
  };

  const updateProjectOnDatabaseDebounced = useCallback(debounce(300, updateProjectOnDatabase), []);

  const updateProject = async (
    project: ProjectModel,
    projectImage: File | null = null,
    currentUser: UserType|null,
    lazyUpdate = false,
  ): Promise<ProjectModel> => {
    if (lazyUpdate) {
      await updateProjectOnDatabaseDebounced(project, projectImage, currentUser);
    } else {
      await updateProjectOnDatabase(project, projectImage, currentUser);
    }
    return project;
  };

  const generateReport = async (project: ProjectModel,
    currentUser: UserType|null): Promise<ReportCalculationsInfoResponse> => {
    const apiConfig = new ApiRequestBuilder()
      .setEndpoint(BACKEND_API_ENDPOINTS.projectReport, { projectId: project.uid })
      .setToken(currentUser?.accessToken)
      .build();
    return callApi(apiConfig)
      .catch((err) => Promise.reject(err));
  };

  return {
    getProject,
    getProjects,
    addProject,
    removeProject,
    updateProject,
    generateReport,
  };
};

export default ProjectDbService;
