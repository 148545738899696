import * as querystring from 'query-string';
import { ApiRequestConfig } from '../types/api-requests/ApiRequestConfig';
import { ApiRequestConfigInfo } from '../types/api-requests/ApiRequestConfigInfo';
import { NameValueMap } from '../types/KeyValueMap';
import authenticationService from './authentication.service';

const useApiRequestService = () => {
  const handleResponse = (response: any) => response.json().then((data: any) => {
    // TODO: This is no longer valid, replace with actual code
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.clearTokenLogin();
        window.location.reload();
      }
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });

  const executeApiRequest = async (apiCallInfo: ApiRequestConfigInfo, body?: NameValueMap<any>,
    handleLoading = false, handleErrorNotification = false) => {
    const fetchOptions: RequestInit = {
      method: apiCallInfo.method,
    };
    const fetchHeaders: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (apiCallInfo.sendToken) {
      fetchHeaders.Authorization = `Bearer ${authenticationService.getAccessToken()}`;
    }

    if (apiCallInfo.sendFiles) {
      if (body && body.projectImage) {
        const formDataBody = new FormData();
        const imageBlob = new File([body.projectImage], body.projectImage.name, { type: body.projectImage.type });
        formDataBody.append('projectImage', imageBlob);
        delete body.projectImage;
        Object.keys(body).forEach((element) => {
          if (body) {
            formDataBody.append(element, JSON.stringify(body[element]));
          }
        });
        fetchOptions.body = formDataBody;
        delete fetchHeaders['Content-Type'];
      } else {
        fetchOptions.body = JSON.stringify(body); // no files to transfer => JSON-Body
      }
    } else if (body && (apiCallInfo.method === 'POST' || apiCallInfo.method === 'PUT')) {
      fetchOptions.body = JSON.stringify(body);
    }

    fetchOptions.headers = fetchHeaders;

    if (handleLoading) {
      /* thisNotificationId = this.notificationsService.showNotification(
          {type: 'loading', message: 'NotificationPleaseWait', delayShowMs: 1000}
      ); */
    }
    let urlApiEndpoint = apiCallInfo.endpoint;
    if ('replaceUrl' in apiCallInfo && apiCallInfo.replaceUrl) {
      Object.keys(apiCallInfo.replaceUrl).forEach((replaceKey) => {
        if (apiCallInfo.replaceUrl) {
          urlApiEndpoint = urlApiEndpoint.replace((`{${replaceKey}}`), (`${apiCallInfo.replaceUrl[replaceKey]}`));
        }
      });
    }
    urlApiEndpoint = process.env.REACT_APP_API_BASEURL + urlApiEndpoint;

    // eslint-disable-next-line no-param-reassign
    if (!body) { body = {}; }
    body.code = process.env.REACT_APP_API_CODE;

    if (body && apiCallInfo.method === 'GET') {
      // url params
      const queryString = querystring.stringify(body);
      urlApiEndpoint = `${urlApiEndpoint}?${queryString}`;
    } else {
      // PUSH|PUT still need code in querystring for azure function auth
      const queryString = querystring.stringify({ code: body.code });
      urlApiEndpoint = `${urlApiEndpoint}?${queryString}`;
      delete body.code;
    }
    return fetch(urlApiEndpoint, fetchOptions)
      .then(handleResponse).then(
        (result) => result,
        (err) => {
          if (handleErrorNotification) {
            // self.notificationsService.showNotification( {type: 'error', message: errorCodeString});
          }
          console.log('executeApiRequest Error:', err);
          return Promise.reject(err);
        },
      );
  };

  const callApi = async (fullApiInfo: ApiRequestConfig) => {
    const apiCallInfo = fullApiInfo.info;
    const value = fullApiInfo.body;
    const { options } = fullApiInfo;
    if (!apiCallInfo) {
      return Promise.resolve(undefined);
    }
    return executeApiRequest(apiCallInfo, value, options.handleLoading, options.handleErrorNotification);
  };

  return { callApi };
};

export default useApiRequestService;
