/* eslint-disable import/no-unresolved */
import { getLocaleConfigByDomain } from '@core/src/services/language.service';
import { UrlParamType } from '@core/src/types/GlobalTypes';
import { LOCALE_MARKET_MAP } from '@frontend/types/LocalMarketMap';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';

export interface ApplicationProviderService {
  getCountry: () => string | undefined,
  locale: string,
  market: string,
  isLocaleInURL: boolean,
  localeSet: boolean,
}

export interface ApplicationProviderProps {
  children?: React.ReactNode;
}

const ApplicationContext = createContext<ApplicationProviderService>({} as ApplicationProviderService);

const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const { locale } = useParams<UrlParamType>();
  const domainLocale = getLocaleConfigByDomain(window.location.hostname);
  const [appLocale, setAppLocale] = useState<string>('xx-XX');
  const [market, setMarket] = useState<string>('');
  const [isLocaleInURL, setIsLocaleInURL] = useState<boolean>(false);
  const [localeSet, setLocaleIsSet] = useState<boolean>(false);
  const getCountry = () => (locale ? locale.split('-')[1] : undefined);

  useEffect(() => {
    if (locale) {
      setAppLocale(locale);
      setMarket(LOCALE_MARKET_MAP[locale?.toLowerCase()]);
      setIsLocaleInURL(true);
      setLocaleIsSet(true);
    }
    if (!locale && domainLocale) {
      setAppLocale(domainLocale);
      setMarket(LOCALE_MARKET_MAP[domainLocale?.toLowerCase()]);
      setIsLocaleInURL(false);
      setLocaleIsSet(true);
    }
  }, [locale, market]);

  return (
    <ApplicationContext.Provider
      value={{
        getCountry,
        locale: appLocale,
        isLocaleInURL,
        market,
        localeSet,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplication = () => useContext(ApplicationContext);

export default useApplication;
export { ApplicationProvider, ApplicationContext };
