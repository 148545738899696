import React, { createContext, useContext } from 'react';
import { SelectionObject } from '../types/classes/SelectionObject';
import useNotifications from './NotificationProvider';
import useTranslations from './Translations';

export interface ProductProviderProps {
  children?: React.ReactNode;
  datalayer: ProductDatalayer;
}

export interface ProjectServiceProvider {
  getProducts: (marketId?: string) => Promise<SelectionObject[]>;
}

export interface ProductDatalayer {
  getProducts: (marketId?: string) => Promise<SelectionObject[]>;
}

const ProductContext = createContext<ProjectServiceProvider>({
  getProducts: () => new Promise<SelectionObject[]>((resolve) => { resolve([]); }),
});

const ProductProvider = ({ children, datalayer }: ProductProviderProps) => {
  const { getTranslation } = useTranslations();
  const { addNotification } = useNotifications();

  const getProducts = async (marketId?: string) => new Promise<SelectionObject[]>((resolve, reject) => {
    datalayer.getProducts(marketId)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        addNotification(getTranslation('TextErrorProductLoad'));
        reject(err);
      });
  });

  return (
    <ProductContext.Provider value={{ getProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

const useProductProvider = () => useContext(ProductContext);

export default useProductProvider;
export { ProductProvider };
