import React, { useContext } from 'react';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { NotificationItem } from '../components/basic/NotificationItem';

export interface NotificationProviderType {
  addNotification: (message: string) => void;
}

export interface NotificationProviderProps {
  children?: React.ReactNode;
}

const NotificationContext = React.createContext<NotificationProviderType>({} as NotificationProviderType);

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const addNotification = (message: string) => {
    Store.addNotification({
      message,
      type: 'danger',
      insert: 'top',
      container: 'bottom-left',
      width: 400,
      animationIn: ['animate__animated animate__fadeInLeft animate__faster'], // `animate.css v4` classes
      animationOut: ['animate__animated animate__fadeOut animate__faster'], // `animate.css v4` classes
      content: <NotificationItem appearance="danger">{message}</NotificationItem>,
    });
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <ReactNotifications />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationContext);

export default useNotifications;
export { NotificationProvider };
