export const getFileFromUrl = async (url: string, fileName: string) => {
  const imageFetch = await fetch(url).catch(() => Promise.reject(Error('File not downloaded')));
  const imageBlob = await imageFetch.blob().catch(() => Promise.reject(Error('Could not create file blob')));
  return new File([imageBlob], fileName);
};

export const buildTranslatedUrl = (pathname: string, locale: string, isLocaleInUrl: boolean) : string => {
  if (pathname[0] === '/') {
    if (isLocaleInUrl) {
      return `/${locale}${pathname}`;
    }
    return `${pathname}`;
  }

  if (isLocaleInUrl) {
    return `/${locale}/${pathname}`;
  }
  return `/${pathname}`;
};
