import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import useOverlay from '../../context/LoadingOverlay';
import { LoadingSpinner } from './LoadingSpinner';

export interface LoadingSpinnerProps {
    text?: string;
    area?: string;
    delay?: number;
}

// SpinnerImage is an animated SVG, to be loaded in advance
// const SpinnerImage = require('../../icons/Cube-1s-200px.svg');

const LoadingOverlay = ({ area }: LoadingSpinnerProps) => {
  const { promiseInProgress } = usePromiseTracker({ area, delay: 0 });
  const [isSpinning, setSpinning] = useState(false);
  const { message, setOverlayMessage } = useOverlay();

  let timer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (promiseInProgress) {
      setSpinning(true);
      timer = setTimeout(() => {
        setSpinning(false);
        if (!promiseInProgress) {
          setOverlayMessage('');
        }
      }, 500);
    }
  }, [promiseInProgress]);

  useEffect(() => () => {
    clearTimeout(timer);
  }, []);

  return (
    <>
      {
        (promiseInProgress || isSpinning)
          ? (
            <>
              <div className="loading-overlay">&nbsp;</div>
              <div className="loading-wrapper">
                <LoadingSpinner />
                <span className="loading-text">{message}</span>
              </div>
            </>
          )
          : null
        }
    </>
  );
};

export default LoadingOverlay;
