/* eslint-disable object-curly-newline */
import React, { createContext, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import _, { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ProjectLocalService from '@frontend/services/ProjectLocalService';
import { buildTranslatedUrl, getFileFromUrl } from '@frontend/services/utilities';
import { MarketAdmissibleValues, MarketDefaultValues, ProjectModel, ProjectWallModel } from '../types/classes/Project';
import { ReportCalculationsInfoResponse, ReportWallCalculations } from '../types/classes/ReportCalculationsInfoResponse';
import useOverlay from './LoadingOverlay';
import useNotifications from './NotificationProvider';
import useTranslations from './Translations';
import { ApiRequestConfig } from '../types/api-requests/ApiRequestConfig';
import { UserType } from '../types/classes/UserInfo';
import useUser from './User';
import { SelectionObject } from '../types/classes/SelectionObject';
import useTracking from '../services/useTracking';
import ApiRequestBuilder from '../requests/api-request.builder';
import { BACKEND_API_ENDPOINTS } from '../requests/api-endpoints-list';
import useApiRequestService from '../services/useApiRequestService';
import useApplication from './Application';
// import projectDefaultImage from '../../public/resources/images/project_default.png';

export const TRANSFER_LOCAL_PROJECT_KEY = 'transferProject';
export const TRANSFER_LOCAL_PROJECT_NEW_KEY = 'transferProjectNewKey';
export const REDIRECT_AFTER_PROJECT_TRANSFER = 'redirectAfterProjectTransfer';
export const TRANSFER_LOCAL_PROJECT_DONE = 'transferProjectDone';
export const DETERMINE_MATERIAL_ONCE = 'determineMaterial';

export interface ProjectProviderProps {
  children?: React.ReactNode;
  datalayer: ProjectDatalayer;
}

export interface ProjectServiceProvider {
  projects: ProjectModel[];
  currentProject: ProjectModel | null;
  currentWall: ProjectWallModel | null;
  marketAdmissibleValues: MarketAdmissibleValues | null;
  marketDefaultValues: MarketDefaultValues | null;
  changeCurrentProject: (uid: string) => boolean;
  setProjectByWallId: (wallId: string) => boolean;// (wallId: string) => void;
  addProject: (project: ProjectModel, projectImage: File | null) => Promise<void>;
  cloneProject: (project: ProjectModel) => Promise<void>;
  removeProject: (project: ProjectModel) => void;
  renameProject: (project: ProjectModel, name: string, projectImage: File|null) => void;
  addWall: (project: ProjectModel, wall: ProjectWallModel) => Promise<void>;
  removeWall: (project: ProjectModel, wall: ProjectWallModel) => void;
  updateWall: (project: ProjectModel, wall: ProjectWallModel, lazyUpdate?: boolean) => void;
  getWallById: (wallId: string) => ProjectWallModel | null;
  generateReport: (project: ProjectModel) => Promise<void>;
  setAllReportErrorHighlights: (project: ProjectModel) => void;
}

export interface ProjectDatalayer {
  getProject: (ProjectUid: string, currentUser: UserType|null) => Promise<ProjectModel | null>;
  getProjects: (currentUser: UserType|null) => Promise<ProjectModel[]>;
  addProject: (project: ProjectModel, projectImage: File|null, currentUser: UserType|null) => Promise<ProjectModel>;
  removeProject: (project: ProjectModel, currentUser: UserType|null) => Promise<void>;
  updateProject: (
    project: ProjectModel,
    projectImage: File | null,
    currentUser: UserType | null,
    lazyUpdate?: boolean,
  ) => Promise<ProjectModel>;
  generateReport: (project: ProjectModel, currentUser: UserType|null) => Promise<ReportCalculationsInfoResponse>;
}

export interface ProjectDatalayerProps {
  callApi: (fullApiInfo: ApiRequestConfig) => Promise<any>;
}

const ProjectContext = createContext<ProjectServiceProvider>({
  projects: [],
  currentProject: null,
  currentWall: null,
  marketAdmissibleValues: null,
  marketDefaultValues: null,
  changeCurrentProject: () => false,
  setProjectByWallId: () => false,
  addProject: async () => undefined,
  cloneProject: async () => undefined,
  removeProject: () => undefined,
  renameProject: () => undefined,
  addWall: async () => undefined,
  removeWall: () => undefined,
  updateWall: () => undefined,
  getWallById: () => null,
  generateReport: async () => undefined,
  setAllReportErrorHighlights: () => undefined,
}); // TODO, for some reason the "as ProjectServiceProvider" trick is not working here, why?

function ProjectProvider({ children, datalayer }: ProjectProviderProps) {
  const { callApi } = useApiRequestService();
  const LocalStorageService = ProjectLocalService({ callApi });
  const [currentProject, setCurrentProject] = useState<ProjectModel | null>(null);
  const [currentWallId, setCurrentWallId] = useState<string | null>(null);
  const [projects, setProjects] = useState<ProjectModel[]>([]);
  const [marketAdmissibleValues, setMarketAdmissibleValues] = useState<MarketAdmissibleValues|null>(null);
  const [marketDefaultValues, setMarketDefaultValues] = useState<MarketDefaultValues|null>(null);
  const { locale, isLocaleInURL, market, localeSet } = useApplication();
  const { currentUser } = useUser();
  const { setOverlayMessage } = useOverlay();
  const { getTranslation } = useTranslations();
  const { addNotification } = useNotifications();
  const [contextLoaded, setContextLoaded] = useState<boolean>(false);
  const history = useHistory();
  const track = useTracking();

  const getProject = (projectUid: string) => projects.find((project) => project.uid === projectUid) || null;

  const checkFixWallFaces = (project: ProjectModel | null) :ProjectModel | null => {
    if (project !== null) {
      project.walls.forEach((wall, iw) => {
        wall.wallObjects.forEach((wallObject, iwo) => {
          if (wallObject.wallFace === undefined) {
            project.walls[iw].wallObjects[iwo] = { ...wallObject, ...{ wallFace: 'Front' } };
          }
        });
      });
    }
    return project;
  };

  useEffect(() => {
    // load market admissible values
    if (locale && localeSet) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getMarketAdmissibleValues,
          { marketId: market })
        .build();
      callApi(apiConfig)
        .then((response) => {
          if (response.InstallationSystems) {
            setMarketAdmissibleValues({
              installationSystems: response.InstallationSystems,
              fireProtectionTypes: response.FireProtectionTypes,
            });
          }
        })
        .catch();
    } else {
      setMarketAdmissibleValues(null);
    }
  }, [locale]);

  useEffect(() => {
    // load market default values
    if (locale && localeSet) {
      const apiConfig = new ApiRequestBuilder()
        .setEndpoint(BACKEND_API_ENDPOINTS.getMarketDefaultValues,
          { marketId: market })
        .build();
      callApi(apiConfig)
        .then((response) => {
          if (response.GisDefaultValues) {
            setMarketDefaultValues({
              gisDefaultValues: {
                prefabricate: response.GisDefaultValues.Prefabricate,
                prefabricationMaxDimension1: (response.GisDefaultValues.PrefabricationMaxDimension1 * 100).toString(),
                prefabricationMaxDimension2: (response.GisDefaultValues.PrefabricationMaxDimension2 * 100).toString(),
              },
            });
          }
        })
        .catch();
    } else {
      setMarketDefaultValues(null);
    }
  }, [locale]);

  useEffect(() => {
    if (currentProject) {
      setCurrentProject(checkFixWallFaces(getProject(currentProject.uid)));
    }
  }, [projects]);

  const changeCurrentProject = (projectUid: string) => {
    const thisCurrentProject = getProject(projectUid);
    if (thisCurrentProject) {
      setCurrentProject(checkFixWallFaces(thisCurrentProject));
      setCurrentWallId(null); // reset active wall to prevent dragging
      return true;
    }
    return false;
  };

  const setProjectByWallId = (wallId: string): boolean => {
    const project = projects.find((filterProject) => filterProject.walls
      .findIndex((projectWall) => projectWall.id === wallId) >= 0);
    if (project) {
      setCurrentProject(checkFixWallFaces(project));
      setCurrentWallId(wallId);
      return true;
    }
    return false;
  };

  const getCurrentWall = () => {
    const project = projects.find((filterProject) => filterProject.walls
      .findIndex((projectWall) => projectWall.id === currentWallId) >= 0);
    return project?.walls.find((filterWall: ProjectWallModel) => filterWall.id === currentWallId) || null;
  };

  const getWallById = (wallId: string) => {
    const project = projects.find((filterProject) => filterProject.walls
      .findIndex((projectWall) => projectWall.id === wallId) >= 0);
    return project?.walls.find((filterWall: ProjectWallModel) => filterWall.id === wallId) || null;
  };

  const addProject = async (project: ProjectModel, projectImage: File | null) => {
    setOverlayMessage(getTranslation('LabelLoadingCreatingProject'));
    const response = await trackPromise(datalayer.addProject(project, projectImage, currentUser))
      .catch((error) => {
        // TODO: Handle errors
        addNotification(getTranslation('TextErrorDefaultAPI'));
        // eslint-disable-next-line no-console
        console.log(error);
        return error;
      });
    setProjects([...projects, response]);
  };

  const cloneProject = async (project: ProjectModel) => {
    setOverlayMessage(getTranslation('LabelLoadingCreatingProject'));
    const projectClone = cloneDeep(project);

    delete projectClone._id;
    projectClone.uid = uuidv4();
    delete projectClone.details.imageUrl;
    delete projectClone.lastReport;
    projectClone.lastReport = undefined;
    projectClone.createdAt = new Date();
    projectClone.lastModified = new Date();
    projectClone.details.title = (`${getTranslation('LabelCopyOf')} ${projectClone.details.title}`).slice(0, 99);
    projectClone.walls = projectClone.walls.map((wall) => {
      const wallClone = cloneDeep(wall);
      wallClone.id = uuidv4();
      return wallClone;
    });

    const clonedProject = await trackPromise(datalayer.addProject(projectClone, null, currentUser))
      .catch((error) => {
        // TODO: Generally handle errors
        addNotification(getTranslation('TextErrorDefaultAPI'));
        console.log('addProjectError', error);
        return error;
      });
    await setProjects([...projects, clonedProject]);
  };

  const removeProject = (projectToRemove: ProjectModel) => {
    datalayer.removeProject(projectToRemove, currentUser)
      .then(() => {
        setProjects(projects.filter((project) => project.uid !== projectToRemove.uid));
      })
      .catch((error) => {
        // TODO: Handle errors
        addNotification(getTranslation('TextErrorDefaultAPI'));
        // eslint-disable-next-line no-console
        console.log(error);
        return error;
      });
  };

  const updateProject = (
    updatedProject: ProjectModel,
    projectImage: File | null = null,
    lazyUpdate = false,
  ): Promise<void> => new Promise((resolve, reject) => {
    setProjects(projects.map((project) => (project.uid === updatedProject.uid ? updatedProject : project)));
    datalayer.updateProject(updatedProject, projectImage, currentUser, lazyUpdate)
      .then(() => {
        resolve();
      }).catch((error) => {
        // TODO: Handle errors
        addNotification(getTranslation('TextErrorDefaultAPI'));
        // eslint-disable-next-line no-console
        console.log(error);
        reject(error);
        return error;
      });
  });

  // TODO: why two functions?
  const renameProject = (project: ProjectModel, name: string, projectImage: File | null) => {
    const newProject = { ...project };
    newProject.details = { ...project.details };
    newProject.details.title = name;
    newProject.lastModified = new Date();
    newProject.lastReport = undefined;
    updateProject(newProject, projectImage, false);
  };

  const addWall = async (project: ProjectModel, wall: ProjectWallModel) => {
    const newProject = { ...project };
    newProject.walls = [...project.walls, wall];
    newProject.lastReport = undefined;
    setOverlayMessage(getTranslation('LabelLoadingCreatingWall'));
    await trackPromise(updateProject(newProject, null, false));
  };

  const removeWall = (project: ProjectModel, wall: ProjectWallModel) => {
    const newProject = { ...project };
    newProject.walls = project.walls.filter(((w) => w.id !== wall.id));
    newProject.lastReport = undefined;
    updateProject(newProject, null, false);
  };

  const updateWall = (project: ProjectModel, wall: ProjectWallModel, lazyUpdate = false) => {
    const newProject = { ...project };
    newProject.walls = newProject.walls.map((w) => (w.id === wall.id ? wall : w));
    newProject.lastReport = undefined;
    updateProject(newProject, null, lazyUpdate);
  };

  const setAllReportErrorHighlights = () => {
    const wallCalculations = currentProject?.lastReport?.reportData?.wallCalculations;
    if (wallCalculations === undefined || !currentProject) return;

    const updatedWalls: ProjectWallModel[] = [];
    // Loop over report-calculations: only update where necessary
    Object.values(wallCalculations).forEach((calc: ReportWallCalculations) => {
      const wall = getWallById(calc.id);

      if (wall) {
        const updatedWall = { ...wall };
        updatedWall.highlightErrors = true;

        // Check first which objects should be displayed as error
        const productsWithErrors: SelectionObject[] = [];
        calc.events.forEach((e) => {
          const product = updatedWall.wallObjects.find((so) => e.ClientElementId === so.uid);
          if (product && (e.Severity === 'Warning' || e.Severity === 'Error')) {
            productsWithErrors.push(product);
          }
        });

        // Update all objects and set state. Objects not found previously must be set to hasError = false
        updatedWall.wallObjects = updatedWall.wallObjects.map((we) => {
          const updatedProduct = _.cloneDeep(we);
          updatedProduct.hasError = productsWithErrors.includes(we);
          return updatedProduct;
        });

        updatedWalls.push(updatedWall);
      }
    });

    // Update Project with new wall-objects
    if (updatedWalls.length > 0) {
      const newProject = { ...currentProject };
      newProject.walls = newProject.walls.map((w) => {
        const updatedWall = updatedWalls.find((uw) => uw.id === w.id);
        if (updatedWall !== undefined) {
          return updatedWall;
        }
        return w;
      });

      updateProject(newProject, null, false);
    }
  };

  const generateReport = async (project: ProjectModel): Promise<void> => {
    setOverlayMessage(getTranslation('ActionDetermineMaterialInProgress'));
    return trackPromise(datalayer.generateReport(project, currentUser)
      .then((response) => {
        const newProject = { ...project };
        newProject.lastReport = {
          createdAt: new Date(),
          isObsolete: false,
          reportData: response,
        };
        updateProject(newProject, null, false);
        track.trackEvent('calculation-finished', 'success', 'calculation-succeeded');
      })
      .catch((error) => {
        // TODO: Handle errors
        addNotification(getTranslation('TextErrorDefaultAPI'));
        // eslint-disable-next-line no-console
        console.log(error);
        track.trackEvent('calculation-finished', 'failure', 'calculation-failed');
        return Promise.reject(error);
      }));
  };

  const transferProjectFromLocalStorageToDB = async (uid: string) => {
    const project = await LocalStorageService.getProject(uid, currentUser)
      .catch(() => Promise.reject(Error('Could not get project from localStorage')));
    const clonedProject = cloneDeep(project);
    let projectImage: File | null = null;
    if (clonedProject && project) {
      if (clonedProject.details?.imageUrl) {
        const imagePath = clonedProject.details.imageUrl.replace('_sm.', '.');
        const imageEnding = _.last(clonedProject.details.imageUrl.split('.'));
        delete clonedProject?.details.imageUrl;
        if (imageEnding !== undefined) {
          projectImage = await getFileFromUrl(imagePath, `projectImage.${imageEnding}`)
            .catch(() => null);
        }
      }

      // Project needs new uid, save it to localstore to use later
      clonedProject.uid = uuidv4();
      localStorage.setItem(TRANSFER_LOCAL_PROJECT_NEW_KEY, clonedProject.uid);
      await addProject(clonedProject, projectImage);
      await LocalStorageService.removeProject(project, currentUser);
      return projectImage !== null;
    }
    return false;
  };

  useEffect(() => {
    // Check if a project should be transfered from localStorage to DB
    const transferProjectKey = localStorage.getItem(TRANSFER_LOCAL_PROJECT_KEY);
    if (transferProjectKey && currentUser) {
      localStorage.removeItem(TRANSFER_LOCAL_PROJECT_KEY);
      transferProjectFromLocalStorageToDB(transferProjectKey)
        .then((doReload) => {
          localStorage.setItem(TRANSFER_LOCAL_PROJECT_DONE, '1');
          if (doReload) {
            window.location.reload();
          }
        })
        .catch((e) => console.error(e));
    } else {
      datalayer.getProjects(currentUser).then((loadedProjects) => {
        setProjects(loadedProjects.filter((project) => project.market === market));
        setContextLoaded(true);
      });
    }
  }, [datalayer, currentUser, market]);

  useEffect(() => {
    /** cleanup the transfer from localstorage to db, and redirect * */
    const transferProjectDone = localStorage.getItem(TRANSFER_LOCAL_PROJECT_DONE);
    const transferProjectNewKey = localStorage.getItem(TRANSFER_LOCAL_PROJECT_NEW_KEY);

    if (contextLoaded && projects.length > 0 && transferProjectDone === '1' && transferProjectNewKey) {
      setCurrentProject(getProject(transferProjectNewKey));
      if (currentProject?.uid === transferProjectNewKey && transferProjectDone === '1' && transferProjectNewKey) {
        localStorage.removeItem(REDIRECT_AFTER_PROJECT_TRANSFER);
        localStorage.removeItem(TRANSFER_LOCAL_PROJECT_DONE);
        localStorage.removeItem(TRANSFER_LOCAL_PROJECT_NEW_KEY);
        localStorage.setItem(DETERMINE_MATERIAL_ONCE, transferProjectNewKey);
        history.push(buildTranslatedUrl(`/project/${transferProjectNewKey}`, locale, isLocaleInURL));
      }
    }
  }, [contextLoaded, projects, currentProject]);

  // Every time lastReport is updated, we need to calculate the highlighted objects anew
  useEffect(() => {
    setAllReportErrorHighlights();
  }, [currentProject?.lastReport]);

  return (
    <ProjectContext.Provider
      value={{
        currentProject,
        currentWall: getCurrentWall(),
        projects,
        marketAdmissibleValues,
        marketDefaultValues,
        changeCurrentProject,
        setProjectByWallId,
        addProject,
        cloneProject,
        removeProject,
        renameProject,
        addWall,
        removeWall,
        updateWall,
        getWallById,
        generateReport,
        setAllReportErrorHighlights,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export { ProjectProvider, ProjectContext };
