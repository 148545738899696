import { ApiRequestConfigInfoMap } from '../types/api-requests/ApiRequestConfigInfo';

export const BACKEND_API_ENDPOINTS: ApiRequestConfigInfoMap = {
  gigyaScript: {
    endpoint: '/api/gigya/{locale}/{gigyaEnvironment}',
    method: 'GET',
    sendToken: false,
  },
  login: {
    endpoint: '/api/gigya/login',
    method: 'POST',
    sendToken: false,
  },
  products: {
    endpoint: '/api/products/{marketId}',
    method: 'GET',
    sendToken: false,
  },
  getNewClientId: {
    endpoint: '/api/proplanner/client',
    method: 'POST',
    sendToken: false,
  },
  getMarketAdmissibleValues: {
    endpoint: '/api/proplanner/market/{marketId}/admissibleValues',
    method: 'GET',
    sendToken: false,
  },
  getMarketDefaultValues: {
    endpoint: '/api/proplanner/market/{marketId}/defaultValues',
    method: 'GET',
    sendToken: false,
  },
  getUserProjects: {
    endpoint: '/api/projects/user',
    method: 'GET',
    sendToken: true,
  },
  getProject: {
    endpoint: '/api/projects/user/{projectId}',
    method: 'GET',
    sendToken: true,
  },
  projectCreate: {
    endpoint: '/api/projects',
    method: 'POST',
    sendToken: true,
    sendFiles: true,
  },
  projectDelete: {
    endpoint: '/api/projects/{projectId}',
    method: 'DELETE',
    sendToken: true,
  },
  projectReportGuest: {
    endpoint: '/api/projects/report',
    method: 'POST',
    sendToken: false,
  },
  projectUpdate: {
    endpoint: '/api/projects/{projectId}',
    method: 'PUT',
    sendToken: true,
    sendFiles: true,
  },
  projectReport: {
    endpoint: '/api/projects/{projectId}/report',
    method: 'GET',
    sendToken: true,
  },
  uploadAnonymousProjectImage: {
    endpoint: '/api/projects/anonymous-image/{projectId}',
    method: 'POST',
    sendToken: false,
    sendFiles: true,
  },
  updateAnonymousProjectImage: {
    endpoint: '/api/projects/anonymous-image/{projectId}',
    method: 'PUT',
    sendFiles: true,
  },
  deleteAnonymousProject: {
    endpoint: '/api/projects/anonymous-project/{projectId}',
    method: 'DELETE',
  },
  sendMailRequest: {
    endpoint: '/api/mail/send',
    method: 'POST',
    sendToken: false,
  },
};
