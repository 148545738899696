import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import useTranslations from '../context/Translations';
import geberitLogo from '../images/geberit_logo.svg';

const Footer = () => {
  const { getTranslation } = useTranslations();

  return (
    <footer className="c-footer footer-container">
      <div className="container">
        <div className="c-footer__wrapper">
          <div className="footer-logo">
            <img src={geberitLogo} title="GEBERIT" alt="GEBERIT" className={`header-logo ${isMobileOnly ? 'mobile' : ''}`} />
          </div>
          <div className="footer-content c-footer__nav">
            <p className="footer-copyright">
              &copy; <span className="curren-year">{new Date().getFullYear()}</span> {getTranslation('LabelCopyRight')}
            </p>
            <a className="footer-link" href={getTranslation('LinkFooterImpressum')} target="_blank">{getTranslation('LabelFooter_impressum')}</a>
            <a className="footer-link" href={getTranslation('LinkFooterDisclaimer')} target="_blank">{getTranslation('LabelFooter_disclaimer')}</a>
            <a className="footer-link" href={getTranslation('LinkFooterDataPrivacy')} target="_blank">{getTranslation('LabelFooter_data-privacy')}</a>
            <a className="footer-link cookie-consent-link ucShow_">{getTranslation('LabelFooter_cookie-consent')}</a>
            <a className="footer-link" href={getTranslation('LinkFooterEuDeclarations')} target="_blank">{getTranslation('LabelFooter_eu-declarations')}</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
