import { ProjectDatalayer } from '../context/Project';
import authenticationService from './authentication.service';
import useApiRequestService from './useApiRequestService';
import ProjectDbService from './ProjectDbService';
import ProjectLocalService from './ProjectLocalService';

const useDatalayer = (): ProjectDatalayer => {
  const { callApi } = useApiRequestService();

  if (authenticationService.hasTokenLogin()) {
    return ProjectDbService({ callApi });
  }
  return ProjectLocalService({ callApi });
};

export default useDatalayer;
