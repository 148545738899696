import React from 'react';

export interface ButtonProps {
  onClick: () => void;
  children?: React.ReactNode;
  classes?: string;
  color?: 'primary' | 'secondary' | 'flat' | 'roundedBG' | 'iconSquare' | 'iconSquareAlternative' | 'iconFlat';
  disabled?: boolean;
  active?: boolean;
  displayBlock?: boolean;
  displayType?: 'default' | 'link';
  fullWidth?: boolean;
  icon?: string;
  pressed?: boolean;
  type?: "button" | "submit" | "reset";
  size?: 'm' | 'l' | 'xl';
  title?: string;
}

const colorClasses = {
  primary: 'btn__primary',
  secondary: 'btn__secondary',
  flat: 'btn__flat',
  roundedBG: 'btn__rounded btn__rounded__bg',
  iconSquare: 'btn__icon btn__icon-square',
  iconSquareAlternative: 'btn__icon btn__icon-square alternative',
  iconFlat: 'btn__icon btn__icon-flat',
};

const displayTypeClasses = {
  default: 'btn__default',
  link: 'btn__link',
};

const sizeClasses = {
  m: 'btn__size__m',
  l: 'btn__size__l',
  xl: 'btn__size__xl',
};

function Button({
  onClick,
  children,
  classes,
  color = 'primary',
  disabled,
  active,
  displayBlock = false,
  displayType = 'default',
  fullWidth = false,
  icon,
  pressed = false,
  size = 'm',
  type = 'button',
  title,
}: ButtonProps) {
  const classNames = [
    colorClasses[color],
    displayTypeClasses[displayType],
    sizeClasses[size],
  ];

  if (classes) classNames.push(classes);
  if (disabled) classNames.push('disabled');
  if (active) classNames.push('active');
  if (displayBlock) classNames.push('btn__block');
  if (fullWidth) classNames.push('btn__full');
  if (pressed) classNames.push('btn__pressed');

  return (
    <button type={type} disabled={disabled} onClick={onClick} className={classNames.join(' ')} title={title}>
      {(icon)
        && <i className={`${icon}`} />}
      {children}
    </button>
  );
}

export default Button;
