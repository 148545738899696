import React from 'react';
import useTranslations from '../../context/Translations';
import { ReactComponent as ErrorIcon } from '../../images/icons/not_available.svg';

type NotificationItemTypes = 'success' | 'danger' | 'info' | 'default' | 'warning';

const toastTitleKeys: {[K in NotificationItemTypes]: string} = {
  danger: 'LabelError',
  info: 'LabelInfo',
  success: 'LabelSuccess',
  warning: 'LabelWarning',
  default: 'LabelInfo',
};

interface NotificationItemProps {
  appearance: NotificationItemTypes,
  children: React.ReactNode,
}

const NotificationItem = ({ appearance, children }: NotificationItemProps) => {
  const { getTranslation } = useTranslations();

  return (
    <div className="notification-item-container">
      <div className={`icon-container ${appearance}`}>
        <ErrorIcon className="icon web20-icon" />
      </div>

      <div className={`content-container ${appearance}`}>
        <p>
          {getTranslation(toastTitleKeys[appearance])}
        </p>
        {children}
      </div>
    </div>
  );
};

export { NotificationItem };
